<template>
  <section id="pf-dashboard-global">
    <p>Costos y Valorización Blending para PF1</p>
    <hr class="solid">
    <b-card>
      <div class="card-header">
        <!-- Title & SubTitle -->
        <h5 class="font-weight-bolder">
          Estas viendo la mejor planificación de la Semana {{ semana }}, realizada por {{ client }}
        </h5>
      </div>
    </b-card>

    <b-row>
      <b-col cols="4" v-for="(item, index) in itemsData1" :key="index">
        <b-card class="text-center">

          <div class="heading-elements">
            <ul class="list-inline col text-right">
              <li :id="'tooltip-'+index" >
                <feather-icon v-b-tooltip.html :title="item.tooltip" variant="outline-success" icon="HelpCircleIcon" size="20" />
              </li>
            </ul>
          </div>
          <b-avatar class="mb-1" :variant="item.color" size="45">
            <feather-icon size="21" :icon="item.icon" />
          </b-avatar>
          <div class="truncate">
            <h5 class="mb-25 font-weight-bolder">{{ item.title }}</h5>
            <span>{{ item.subtitle }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- % satisfaccion demanda -->
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="chart1"></bruna-element-loader>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="chart2"></bruna-element-loader>
      </b-col>
    </b-row>

  </section>
</template>
<script>
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import { BRow, BCol, BButton, BTooltip } from "bootstrap-vue";
import useApiServices from '@/services/useApiServices.js';
import router from '@/router';
import axios from "@axios";
export default {

  mounted() {

    this.semana = this.$route.params.semana;

    axios.get(useApiServices.globalHistoricoStatistics1 + "/" + this.semana)
      .then((response) => {
        this.itemsData1 = response.data;
      })
      .catch((error) => {

      });
  },

  data() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let semana = this.$route.params.semana

    return {
      chart1: useApiServices.globalHistoricoChart1 + "?semana=" + semana,
      chart2: useApiServices.globalHistoricoChart2 + "?semana=" + semana,
      itemsData1: [],
      client: userData.name,
      semana:this.$route.params.semana
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BTooltip,
    BrunaElementLoader
  },

  methods: {
    goToBack() {
      router.push({ name: 'pf-historico-planificacion' })
    }
  },
};
</script>